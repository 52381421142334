import React from 'react'
import { connect } from 'react-redux';
import { updateAvatar } from '../../actions/profile';

class Avatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      avatar: null,
    }

    this.handleFileInput = this.handleFileInput.bind(this);
    this.handleAvatarSubmit = this.handleAvatarSubmit.bind(this);
  }

  handleFileInput(event) {
    this.setState({ avatar: event.target.files[0] });
  }

  handleAvatarSubmit(event) {
    event.preventDefault();

    const { avatar } = this.state;
    const { dispatch } = this.props;

    const formData = new FormData();
    formData.append("avatar", avatar);

    dispatch(updateAvatar(formData));
  }

  render() {
    const { profile } = this.props;

    const avatar_url = profile.data.uuid ?
      `${process.env.REACT_APP_CDN_URL}${profile.data.avatar_path}`
      : null

    return (
      <form onSubmit={this.handleAvatarSubmit}>
        <div className="border rounded-md overflow-hidden p-5 grid mb-2 grid-cols-6 gap-6 shadow">
          <h3 className="font-bold col-span-6">
            Anzeigebild
          </h3>

          {
            profile.data.avatar_path &&
              <div className="col-span-6 sm:col-span-2">
                <img src={avatar_url} alt="Dein Avatar" />
              </div>
          }

          <div className="col-span-6 sm:col-span-4 text-sm">
            <div>
              Das ausgewählte Bild
            </div>

            <ul className="mb-4">
              <li className="text-xs">- muss das JPEG-Format besitzen</li>
              <li className="text-xs">- darf maximal 2 MB gross sein</li>
              <li className="text-xs">- sollte mindestens 400x400 Pixel gross sein</li>
            </ul>

            <input accept="image/jpeg"
              className="mb-4"
              type="file"
              onChange={this.handleFileInput}/>

            { profile.isUpdating ?
              <button
                disabled="disabled"
                className="flex cursor-wait col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-300 focus:outline-none">
                Wird hochgeladen ...
              </button> :
              <button
                type="submit"
                className="flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
                Hochladen
              </button>
            }
          </div>
        </div>
      </form>
    );
  }
}

class AvatarWrapper extends React.Component {
  render() {
    const { profile } = this.props;

    if (profile.data.uuid) {
      return <Avatar {...this.props} />
    } else {
      return <div>Lade Anzeigebild...</div>
    }
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile
  };
}

export default connect(mapStateToProps)(AvatarWrapper);
