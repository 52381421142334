import React from 'react'

import { Link } from 'react-router-dom';
import TextInput from 'components/style/TextInput';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      password_confirmation: '',
      success: false,
    };

    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(event) {
    event.preventDefault();

    // Return right here if the input don't match.
    if (this.state.password !== this.state.password_confirmation) {
      return
    }

    // Return here if password length is no good.
    if (this.state.password.length < 8) {
      return
    }

    // Reset password in the API.
    window.usersApi.post(`/v1/reset_password/${this.props.token}`, this.state)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ success: true });
          return Promise.resolve();
        }

        return Promise.reject();
      });
  }

  renderSuccess() {
    return (
      <div className="text-center max-w-2xl m-auto mt-20">
        <h1 className="text-xl md:text-2xl lg:text-4xl mb-5">
          Passwort geändert
        </h1>
        <p className="text-lg mb-10">
          Du hast dein Passwort erfolgreich geändert und kannst dich nun
          in der mobilen App oder im Portal anmelden. Bei weiteren Fragen
          wende dich gerne jederzeit an unseren Support in dem du uns über
          unser <a className="underline" href="https://www.familienort.com/kontakt">
          Kontaktformular</a> schreibst.
        </p>
        <Link
          className="bg-primary rounded-full text-xl px-6 py-2"
          to="/">
          Zurück zum Start
        </Link>
      </div>
    )
  }

  renderForm() {
    return (
      <div className="text-center max-w-2xl m-auto mt-20">
        <h1 className="text-xl md:text-2xl lg:text-4xl mb-5">
          Neues Passwort vergeben
        </h1>
        <p className="text-gray-700 text-base">
          Hier kannst du dir ein neues Passwort setzen. Das Passwort muss
          mindestens acht Zeichen lang sein.
        </p>
        <form
          className="pt-6 pb-8 mb-4"
          onSubmit={this.submitForm}>

          <div className="mb-4">
            <TextInput type="password"
              placeholder="Passwort"
              className="border py-2 px-3 w-full"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              name="password" />
          </div>

          <div className="mb-4">
            <TextInput type="password"
              value={this.state.password_confirmation}
              className="border py-2 px-3 w-full"
              placeholder="Passwort (Bestätigung)"
              onChange={(e) => this.setState({ password_confirmation: e.target.value })}
              name="password_confirmation"/>
          </div>

          <div className="flex items-center justify-center py-2">
            <button className="bg-primary hover:bg-opacity-75
                               font-bold py-2 px-6 rounded-full focus:outline-none
                               focus:shadow-outline"
              color="primary"
              type="submit">
              Abschicken
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return this.state.success ? this.renderSuccess() : this.renderForm()
  }
}

export default Form;
