import React from 'react'
import { connect } from 'react-redux';
import { loadProfile, updateProfile } from '../../actions/profile';

import Button from 'components/style/Button';
import TextInput from 'components/style/TextInput';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {
        domain: props.profile.domain,
        country: props.profile.country,
        display_name: props.profile.display_name,
        description: props.profile.description,
        website: props.profile.website,
        phone_number: props.profile.phone_number,
        public: props.profile.public,
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProfileChange = this.handleProfileChange.bind(this);
  }

  handleProfileChange(event) {
    const { profile } = this.state;

    profile[event.target.name] = event.target.value;

    this.setState({ profile });
  }

  handlePublicChange(event) {
    const { profile } = this.state;

    profile[event.target.name] = !profile.public

    this.setState({ profile });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { dispatch } = this.props;

    dispatch(updateProfile(this.state.profile));
  }

  render() {
    const { profile } = this.state;

    return (
      <form className="w-full" onSubmit={this.handleSubmit}>
        <div className="bg-white rounded-lg overflow-hidden p-5 grid mb-5 gap-6">
          <h3 className="font-bold col-span-6">
            Dein Profil
          </h3>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Angezeigter Name
            </label>
            <TextInput
              value={profile.display_name}
              name="display_name"
              onChange={this.handleProfileChange}
              name="display_name" />
          </div>

          <Button title="Aktualisieren" primary onClick={this.handleSubmit} />

        </div>
      </form>
    );
  }
}

class ProfileWrapper extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadProfile());
  }

  render() {
    const { profile } = this.props;

    if (profile.uuid) {
      return <Profile {...this.props} />
    } else {
      return <div>Lade Profil</div>
    }
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.data
  };
}


export default connect(mapStateToProps)(ProfileWrapper);
