import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Dashboard from '../dashboard';
import Courses from '../courses';
import Course from '../course';
import Module from '../module';
import Settings from '../settings';
import NotFound from '../404';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MenuButton from 'components/MenuButton';
import Menu from 'components/Menu';
import Logo from 'images/logo.png';

class PrivateApp extends React.Component {
  render() {
    return(
      <React.Fragment>

        <Menu />

        <header className="flex h-20 items-center px-5 justify-between">
          <Link to="/" className="outline-none">
            <img src={Logo} alt="familienort.com" style={{ maxWidth: '65px'}} />
          </Link>

          <MenuButton />
        </header>

        <main>

          <ToastContainer />

          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/kurse" component={Courses} />
            <Route exact path="/kurse/:id" component={Course} />
            <Route exact path="/kurse/:course_id/:id" component={Module} />
            <Route exact path="/einstellungen" component={Settings} />
            <Route component={NotFound} />
          </Switch>

        </main>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    router: state.router
  };
}

export default connect(mapStateToProps)(PrivateApp);
