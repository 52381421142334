import React from 'react'
import { connect } from 'react-redux';

import { loadProfile } from 'actions/profile';
import { loadCourses } from 'actions/courses';

import Placeholder from 'components/style/Placeholder';
import CoursesOverview from 'components/CoursesOverview';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(loadProfile());

    // Load courses a little bit later to not f-k with
    // refresh token action when token is invalid.
    setTimeout(() => {
      this.props.dispatch(loadCourses());
    }, 500);
  }

  render() {
    const { profile: { data: { display_name }}} = this.props;

    return (
      <div className="container m-auto px-5">
        <div>
          <h1 className="text-bold py-5 text-3xl xl:text-5xl mb-2">
            Hey <Placeholder text={display_name} length={8} />,<br />
            schön, dass du da bist!
          </h1>

          <p className="text-lg mb-10 xl:mb-20">Herzlich willkommen in deinem familienort-Portal. Hier findest
            du deine Kurse und Einstellungen. Die Impulse erhältst du exklusiv
            in der App</p>
        </div>

        <div>
          <h2 className="text-2xl xl:text-3xl mb-2">Deine Kurse</h2>

          <CoursesOverview />

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

export default connect(mapStateToProps)(Dashboard);
