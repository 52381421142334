import React from 'react'
import Profile from './Profile';
import Avatar from './Avatar';

class Settings extends React.Component {
  render() {
    return (
      <div className="container flex flex-col m-auto">
        <h1 className="text-bold py-5 text-3xl xl:text-4xl 2xl:text-5xl mb-2">
          Einstellungen
        </h1>

        <Profile />

      </div>
    );
  }
}

export default Settings;
